export const en = {
  header: {
    inputLoaction: {
      placeholder: 'Enter city',
    },
    selectInput: {
      individuals: 'For Individuals',
      legal: 'For Legal Entities',
    },
  },
  maine: {
    title: {
      text: 'Detective Services',
      location: 'in Europe and Ukraine',
    },
    subTitle: {
      text: 'Entrust your case to our detectives and get the first results within',
      time: '24 HOURS',
    },
    button: {
      text: 'Book a Consultation',
    },
  },
  services: {
    title: {
      text: 'Detective Services',
    },
    selectInput: {
      individuals: 'For Individuals',
      legal: 'For Legal Entities',
    },
    card: {
      buttonOrder: {
        text: 'Order',
      },
      buttonDetails: {
        text: 'More info',
      },
    },
    description: {
      button: {
        text: 'backward',
      },
    },
  },
  steps: {
    title: 'How Do We Provide Detective Services?',
    numberOne: {
      title: 'Call',
      description: 'You schedule a consultation with us',
    },
    numberTwo: {
      title: 'Consultation',
      description: 'You receive a consultation from our specialist',
    },
    numberThree: {
      title: 'Instruction',
      description:
        'You receive clear and understandable step-by-step instructions on what to do to solve your problem',
    },
    numberFour: {
      title: 'Information',
      description:
        'You receive clear and complete information about the cost of our services in case you wish to order them from our specialists',
    },
    numberFive: {
      title: 'Order',
      description:
        'You place an order with the necessary list of services and receive them in full, guaranteed',
    },
  },
  gallery: {
    title: 'Our private detectives work both online and offline.',
  },
  footer: {
    location: {
      title: 'Address',
      link: {
        text: 'C/ de les Barques, 3, Ciutat Vella, 46002 Valencia, Valencia, Spain',
      },
    },
    mail: {
      title: 'Email',
    },
    socialLinks: {
      title: 'Social Links',
    },
  },
  modal: {
    title: 'Order',
    inputName: {
      invalidMessage: 'Enter name minimum 4 characters',
      errorMessage: 'Enter name',
      label: 'Name',
    },
    inputPhone: {
      invalidMessage: 'Enter phone number',
      errorMessage: 'Enter phone number',
      label: 'Phone number',
    },
    buttonSubmit: 'Submit',
  },
};
